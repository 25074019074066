import { FC } from "react";
import Footer from "../../../components/layout/LayoutFooter";

const PlaceholderWriting = () => {
  return (
    <div className="item">
      <p> Autor | Tytuł </p>
      <div className="ui raised segment">
        <div className="ui placeholder">
          <div className="image header">
            <div className="line"></div>
            <div className="line"></div>
          </div>
          <div className="paragraph">
            <div className="medium line"></div>
            <div className="short line"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ListOfWritings = () => {
  return (
    <div className="ui massive horizontal divided list">
      <PlaceholderWriting></PlaceholderWriting>
      <PlaceholderWriting></PlaceholderWriting>
      <PlaceholderWriting></PlaceholderWriting>
      <PlaceholderWriting></PlaceholderWriting>
      <PlaceholderWriting></PlaceholderWriting>
      <PlaceholderWriting></PlaceholderWriting>
      <PlaceholderWriting></PlaceholderWriting>
      <PlaceholderWriting></PlaceholderWriting>
    </div>
  );
};

const NewLayout: FC = (props) => {
  return (
    <div className="flex min-h-screen flex-col justify-between bg-neutral-50 text-neutral-900">
      <div>
        <div className="ui divider"></div>

        <div className="ui stackable equal width grid">
          <div className="three wide column">
            <div className="ui three vertical buttons">
              <div className="ui button ">
                <p>STRONA GŁÓWNA</p>
              </div>
              <div className="ui button ">
                <p>STREFA OPOWIEŚCI</p>
              </div>
              <div className="ui button">
                <p>CZYTELNIA</p>
              </div>
              <div className="ui button">
                <p>USTAWIENIA</p>
              </div>
            </div>
          </div>

          <div className="column">
            <div className="ui header">
              <p>ZAPISANE </p>
            </div>
            <div className="ui divider"></div>

            <div className="">
              <div className="ui checkbox">
                <input type="checkbox"></input>
                <label>najnowsze</label>
              </div>

              <div className="ui checkbox">
                <input type="checkbox"></input>
                <label>najdawniejsze</label>
              </div>
            </div>

            <ListOfWritings></ListOfWritings>

            <div className="ui header">
              <p>ODKRYWAJ NOWOŚCI</p>
            </div>
            <div className="ui divider"></div>
            <ListOfWritings></ListOfWritings>

            <div className="ui header">
              <p>
                INSPIRACJE<i className="icon dragon"></i>
              </p>
            </div>
            <div className="ui divider"></div>
            <ListOfWritings></ListOfWritings>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NewLayout;
